import React, {useRef, useEffect, useState} from 'react';
import { DataStore } from '../stores/DataStore';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const Canvas = () => {

    const r = 1000;
    const k_speed_scale = 1;
    
    const canvasRef = useRef(null);
    const contextRef = useRef(null);

    const [x1, setX] = useState(Math.random()*r);
    const [y1, setY] = useState(Math.random());
    const [pointerLocked, setPointerLocked] = useState(false);
    const [startTime, setStartTime] = useState(-1);
    const [mouseXS, setMouseXS] = useState([]);
    const [mouseYS, setMouseYS] = useState([]);
    const [sampleTimes, setSampleTimes] = useState([]);
    const [sensorValues, setSensorValues] = useState([]);
    const [startDataCollection, setStartDataCollection] = useState(false);
    const [colourswitch, setColourSwitch] = useState(0);

    const { prolificID, studyID, sessionID, isStartTimer, initialTime, totalTrial, currentTrial, timer, trialDone } = DataStore.useState(data => data);

    const navigate = useNavigate();

    const hashCode = (s) => {
        return s.split("").reduce(function(a, b) {
          a = ((a << 5) - a) + b.charCodeAt(0);
          return a & a;
        }, 0);
    }

    const canvasDrawReady = () => {
        const canvas = canvasRef.current;
        
        const width = 160;
        const height = 50;

        canvas.width = width * 2;
        canvas.height = height * 2;
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;

        const context = canvas.getContext('2d');

        contextRef.current = context;

        contextRef.current.fillStyle = "black";
        contextRef.current.fillRect(0,0,325,100);

        contextRef.current.beginPath();
        contextRef.current.font = '60px Arial';
        contextRef.current.textAlign = 'left';
        contextRef.current.textBaseline = 'top';
        contextRef.current.fillStyle = 'white';
        contextRef.current.fillText("Start Trial", 30, 25);
        contextRef.current.stroke();
    }
    

    const circular_distance = (a,b) => {
        //this assumes the arena is between 0 and 1
        return Math.min(Math.abs(a-b),Math.abs(1.0-Math.abs(a-b)))
    }

    const excitation = (pos) => {
        // simple gaussian
        let d = 10.0 * circular_distance(pos,0.0);
        if (colourswitch == 0){
            return Math.exp(-(d*d)/(2));
        }else{
            return 1 - Math.exp(-(d*d)/(2));
        }
    }

    const canvasDraw = () => {
        const canvasW = canvasRef.current.clientWidth * 2;
        const canvasH = canvasRef.current.clientHeight * 2;

        contextRef.current.fillRect(0,0,canvasW,canvasH);
        contextRef.current.beginPath();
        contextRef.current.fill();
    }

    const updateVisual = () => {
        let i = ((x1 % r) + r) % r / r;
        let j = ((y1 % r) + r) % r / r;

        let excite = excitation(i);
        let bytevalue = Math.floor(255*excite);
                
        //contextRef.current.fillStyle = `rgb(${Math.floor(255*excite)}, ${Math.floor(255*(1.0-excite))}, 0)`;
        contextRef.current.fillStyle = `rgb(${bytevalue}, ${bytevalue}, ${bytevalue})`;
        
        canvasDraw();

        return excite;
    }

    const canvasLoop = (e) => {
        if (!pointerLocked) return;

        let movementX = e.movementX || e.mozMovementX || e.webkitMovementX || 0;
        let movementY = e.movementY || e.mozMovementY || e.webkitMovementY || 0;

        movementX *= k_speed_scale;
        movementY *= k_speed_scale;

        setX(oldMovementX => oldMovementX + movementX);
        setY(oldMovementY => oldMovementY + movementY);

        let excite = updateVisual();

        if(startDataCollection) {
            const currentTime = Date.now() - startTime;

            setMouseXS(oldMouseXS => [...oldMouseXS, x1]);
            setMouseYS(oldMouseYS => [...oldMouseYS, y1]);
            setSampleTimes(oldSampleTimes => [...oldSampleTimes, currentTime]);
            setSensorValues(oldSensorValues => [...oldSensorValues, excite]);
        }
    }

    const canvasClick = () => {

        if (pointerLocked) {
            console.log('Filtering clicks.');
            return;
        }

        const canvas = canvasRef.current;

        canvas.requestPointerLock = canvas.requestPointerLock ||
        canvas.mozRequestPointerLock ||
        canvas.webkitRequestPointerLock;

        canvas.requestPointerLock();
        setPointerLocked(true);

        canvas
            .requestFullscreen()
            .then(() => {

                canvasRef.current.width = window.innerWidth * 2;
                canvasRef.current.height = window.innerHeight * 2;
                canvasRef.current.style.width = `${window.innerWidth}px`;
                canvasRef.current.style.height = `${window.innerHeight}px`;

                const canvasW = canvas.clientWidth * 2;
                const canvasH = canvas.clientHeight * 2;

                let i = ((x1 % r) + r) % r / r;

                let excite = excitation(i);
                let bytevalue = Math.floor(255*excite);
                
                //contextRef.current.fillStyle = `rgb(${Math.floor(255*excite)}, ${Math.floor(255*(1.0-excite))}, 0)`;
                contextRef.current.fillStyle = `rgb(${bytevalue}, ${bytevalue}, ${bytevalue})`;

            
                contextRef.current.fillRect(0,0,canvasW,canvasH);
                contextRef.current.beginPath();
                contextRef.current.fill();

                setStartDataCollection(true);
                DataStore.update(data => {
                    data.isStartTimer = true
                });                
            });
    }

    const decrementTime = () => {
        DataStore.update(data => {
            data.timer = timer - 1
        });
    }

    useEffect(() => {
        const newColourSwitch = (hashCode(prolificID) + currentTrial) % 2;
        setColourSwitch(newColourSwitch);
    }, [currentTrial]);

    useEffect(() => {
        let timerObj = null;

        if(isStartTimer && timer > 0) {
           timerObj = setInterval(() => {
             decrementTime();
           }, 1000);
        } else {
             if(timer == 0) {
                 setStartDataCollection(false);
                 
                 let trialData = {
                     "prolific_id": prolificID,
                     "sensor_function": excitation.toString(),
                     "mouse_xs" : mouseXS,
                     "mouse_ys" : mouseYS,
                     "sample_times" : sampleTimes,
                     "sensor_values"  : sensorValues,
                     "mouse_sensitivity" : 0,
                     "trial_index" : currentTrial,
                     "k_speed_scale" : k_speed_scale,
                     "peak_colour" : (colourswitch == 0) ? "White" : "Black",
                     "colourswitch" : colourswitch,}
 
                 axios.post('/api/savetrial', trialData)
                 .then((response) => {
                     if(currentTrial >= totalTrial) {
                         axios.post('/api/savetodb', {
                             prolific_id : prolificID,
                             study_id : studyID,
                             session_id : sessionID,
                             is_complete: "True"
                         })
                         .then(response2 => {
                            DataStore.update(data => {
                                data.trialDone = true
                            });
                            navigate("/questionnaire");                            
                         })                        
                     } else 
                     {
                        navigate("/done-trial-item");                    
                     }
                 });               
                 clearInterval(timerObj);
             }            
             clearInterval(timerObj);
        }
 
        return () => clearInterval(timerObj);
    }, [isStartTimer, timer]);

    useEffect(() => {
        canvasDrawReady();
    }, [])
    return <canvas ref={canvasRef} onMouseMove={canvasLoop}  onClick={canvasClick}  />
}

export default Canvas;