import React, {useCallback} from 'react'
import { Routes, Route } from 'react-router-dom';
import IntroductionPage from "./pages/IntroductionPage";
import TrialPage from "./pages/TrialPage";
import TrialItemPage from "./pages/TrialItemPage";
import DoneTrialItemPage from './pages/DoneTrialItemPage';
import QuestionnairePage from './pages/QuestionnairePage';
import { PISPage } from './pages/PISPage';
import LoginPage from './pages/LoginPage';
import AdminPage from './pages/AdminPage';
import './App.css'

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<IntroductionPage />} />
      <Route path="/trial" element={<TrialPage />} />
      <Route path="/trial-item" element={<TrialItemPage />} />
      <Route path="/done-trial-item" element={<DoneTrialItemPage />} />
      <Route path="/PIS" element={<PISPage />} />
      <Route path="/questionnaire" element={<QuestionnairePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/admin" element={<AdminPage />} />
    </Routes>
  )
}

export default App;