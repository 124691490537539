import React, {useState} from 'react'
import { Spinner } from '../components/Spinner';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { DataStore } from '../stores/DataStore';

const LoginPage = () => {
  const [password, setPassword] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  const { isAdminLoggedIn, showLoginError } = DataStore.useState(data => data);
  
  const navigate = useNavigate();

  const handleClick = () => {
    const formData = new FormData();

    formData.append("psswd", password);

    setShowSpinner(true);
    axios.post(
      "/api/login",
      formData,
      {
        headers: {
          "Content-Type" : "multipart/form-data",
          "Accept" : "application/json"
        }
      }
    ).then((response) => {
      setShowSpinner(false);
      if (response.data == 1) {
        DataStore.update(data => {
            data.isAdminLoggedIn = true;
            data.showLoginError = false;
        });
        navigate('/admin');
      } else {
        DataStore.update(data => {
            data.isAdminLoggedIn = false;
            data.showLoginError = true;
        });        
        navigate('/login');
      }
      console.log(response)
    //   navigate('/admin');
    })
  }

  return (
    <>
        <div className="relative flex min-h-screen flex-col justify-center overflow-hidden py-6 sm:py-12">
            <div className='ml-5 mr-5 relative bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10 border-2 border-sky-500 flex justify-center flex-col'>
                <div className="mb-5">
                    <div>
                        <label htmlFor="psswd" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">Password</label>
                        <input 
                            type="password" 
                            id="psswd" 
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 
                                   focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
                                   dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                   required
                                   value={password}
                                   onChange={(e) => setPassword(e.target.value)}
                                   />
                    </div>
                </div>
                <button className="pl-10 pr-10 pb-2 pt-2 bg-sky-500 text-white rounded-md text-center" onClick={handleClick}>
                Login
                </button>

                <div className="mb-2 mt-3">
                    {showLoginError && <div className="text-sm font-medium text-red-700">Wrong Password</div>}
                </div>        
            </div>
        </div>
        <Spinner isVisible={showSpinner}/>
    </>
  )
}

export default LoginPage;