import React, {useEffect, useState} from 'react'
// import questionnaireJson from '../mockdata/questionnaire.json';
import { Spinner } from '../components/Spinner';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import * as FaIcons from "react-icons/fa";

const QuestionnaireData = () => {

  const [questionnaireJson, setQuestionnaireJson] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);

  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;

  const pageCount = Math.ceil(questionnaireJson.length / itemsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayItems = questionnaireJson
        .slice(pagesVisited, pagesVisited + itemsPerPage)
        .map((d, index) => {
            return (
                <tr key={index}>
                    <td>{d.id}</td>
                    <td>{d.prolific_id}</td>
                    <td>{d.q1}</td>
                    <td>{d.q2}</td>
                    <td>{d.q3}</td>
                </tr>                
            )
        });


  useEffect(() => {
    setShowSpinner(true);
    axios.get(
        "/api/questionnaire-list"
      ).then((response) => {
          setQuestionnaireJson(response.data);
          setShowSpinner(false);
      });

  }, []);
  return (
    <>
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Prolific ID</th>
                    <th>Anwser Q1</th>
                    <th>Anwser Q2</th>
                    <th>Anwser Q3</th>
                </tr>
            </thead>
            <tbody>
                {displayItems}
            </tbody>
        </table>
        {pageCount > 0 && (
                <nav className="pagination" role="navigation" aria-label="pagination">
                    <ReactPaginate
                        previousLabel={
                        <FaIcons.FaChevronLeft className="pagination-previous" />
                        }
                        nextLabel={<FaIcons.FaChevronRight className="pagination-next" />}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        pageClassName={"pagination-link"}
                        containerClassName={"pagination-list"}
                        previousLinkClassName={"previous-btns"}
                        nextLinkClassName={"next-btns"}
                        disabledClassName={"pagination-disabled"}
                        activeClassName={"is-current"}
                    />
                </nav>
          )}        
        <Spinner isVisible={showSpinner}/>
    </>
  )
}

export default QuestionnaireData;