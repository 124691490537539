import { Store } from "pullstate";

export const DataStore = new Store({
  prolificID: "",
  studyID: "",
  sessionID: "",
  currentTrial: 0,
  totalTrial: 10,
  isStartTimer: false,
  initialTime: 10,
  timer: 10,
  trialStarted: false,
  trialDone: false,
  isAdminLoggedIn: false,
  showLoginError: false,
});