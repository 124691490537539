import React, {useEffect, useState} from 'react'
import { Spinner } from '../components/Spinner';
import axios from 'axios';
import {ImFloppyDisk} from 'react-icons/im';

const InitializeData = () => {

  const [showQuestionnaireDeletedMessage, setShowQuestionnaireDeletedMessage] = useState(false);
  const [questionnaireDeletedMessage, setQuestionnaireDeletedMessage] = useState("");
  const [showProlificDeletedMessage, setShowProlificDeletedMessage] = useState(false);
  const [prolificDeletedMessage, setProlificDeletedMessage] = useState("");


  const deleteQuestionnaire = () => {
    axios.post(
        "/api/delete-questionnaire",
        {
            "passwordCheck" : "d3l3t3M3"
        }
      ).then((response) => {
        setQuestionnaireDeletedMessage(response.data);
        setShowQuestionnaireDeletedMessage(true);
      });
  }

  const deleteProlific = () => {
    axios.post(
        "/api/delete-prolific",
        {
            "passwordCheck" : "d3l3t3M3"
        }
      ).then((response) => {
        setProlificDeletedMessage(response.data);
        setShowProlificDeletedMessage(true);
      });
  }

  return (
    <>
        <div className="text-red-600">
            Warning initilising the database will erase all its content. Make sure you have requested to backed up the database before you proceed.
        </div>
        <div className="mt-10 custom-block-01">
            <div className="mb-5 flex items-center hover:text-blue-500 cursor-pointer" onClick={deleteQuestionnaire}>
                <ImFloppyDisk /> <span className="ml-2">Intialize Questionnaire Table</span> {showQuestionnaireDeletedMessage && <span className="ml-2 text-red-600">{questionnaireDeletedMessage}</span>} 
            </div>            
            <div className="mb-5 flex items-center hover:text-blue-500 cursor-pointer" onClick={deleteProlific}>
                <ImFloppyDisk /> <span className="ml-2">Intialize Prolific Table</span> {showProlificDeletedMessage && <span className="ml-2 text-red-600">{prolificDeletedMessage}</span>}
            </div>
        </div>
    </>
  )
}

export default InitializeData;