import React, {useEffect, useState} from 'react';
import { DataStore } from '../stores/DataStore';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { Spinner } from '../components/Spinner';

const QuestionnairePage = () => {

  const navigate = useNavigate();
  const { prolificID, trialStarted, trialDone } = DataStore.useState(data => data);
  const [q1Value, setQ1Value] = useState('');
  const [q2Value, setQ2Value] = useState('5');
  const [q3Value, setQ3Value] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  const submitForm = (e) => {
    const formData = new FormData();

    formData.append("q1Value", q1Value);
    formData.append("q2Value", q2Value);
    formData.append("q3Value", q3Value);
    formData.append("prolificID", prolificID);

    setShowSpinner(true);
    axios.post(
        "/api/save-questionnaire",
        formData,
        {
          headers: {
            "Content-Type" : "multipart/form-data",
            "Accept" : "application/json"
          }
        }
      ).then((response) => {
        console.log(response);
        setShowSpinner(false);
        window.location.href = 'https://app.prolific.co/submissions/complete?cc=CHL7WHFM';
      })
  }

  useEffect(() => {
    if(!trialStarted && !trialDone) {
      navigate("/")
    }
  },[]);
  return (
    <>
        <div className="
                        flex
                        justify-center
                       ">
            <div 
                className="
                    container
                    w-10/12
                ">
                                    <h1 
                className="
                text-3xl 
                font-semibold 
                text-slate-600
                mt-3
                ">Questionnaire</h1>
                <div className="mt-5">
                    <div className="mb-10">
                            <div className="mb-2 font-semibold text-lg">
                                Q1: Briefly describe how you believe your mouse movements affected the stimuli that you were presented
                            </div>
                            <div className="">
                                <textarea className="
                                                bg-gray-50 border
                                                border-gray-300 
                                                text-gray-900 
                                                font-semibold text-lg
                                                rounded-lg 
                                                focus:ring-blue-500 
                                                focus:border-blue-500 block 
                                                w-3/4
                                                p-2.5 
                                                dark:bg-gray-700 
                                                dark:border-gray-600 dark:placeholder-gray-400 
                                                dark:text-white dark:focus:ring-blue-500 
                                                dark:focus:border-blue-500"
                                                id="q1Value"
                                                value={q1Value}
                                                onChange={(e) => setQ1Value(e.target.value)}
                                                >

                                </textarea>
                            </div>
                    </div>

                    <div className="mb-10">
                            <div className="mb-2 font-semibold text-lg">
                                Q2: On a scale from 1 - 5, 5 being very confident and 1 being not confident at all how confident are you in your answer to Q1
                            </div>
                            <div className="">
                                <select className="
                                                bg-gray-50 border
                                                border-gray-300 
                                                text-gray-900 
                                                font-semibold text-lg
                                                rounded-lg 
                                                focus:ring-blue-500 
                                                focus:border-blue-500 block 
                                                w-20
                                                p-2.5 
                                                dark:bg-gray-700 
                                                dark:border-gray-600 dark:placeholder-gray-400 
                                                dark:text-white dark:focus:ring-blue-500 
                                                dark:focus:border-blue-500"
                                                id="q2Value"
                                                value={q2Value}
                                                onChange={(e) => setQ2Value(e.target.value)}
                                                >
                                    <option value="5">5</option>
                                    <option value="4">4</option>
                                    <option value="3">3</option>
                                    <option value="2">2</option>
                                    <option value="1">1</option>
                                </select>
                            </div>
                    </div>  

                    <div className="mb-10">
                            <div className="mb-2 font-semibold  text-lg">
                                Q3: Did you have any technical issues that you think may have interfered with your ability to carry out the experiment? If so, please state briefly what they were.
                            </div>
                            <div className="">
                            <textarea  className="
                                                bg-gray-50 border
                                                border-gray-300 
                                                text-gray-900 
                                                font-semibold text-lg
                                                rounded-lg 
                                                focus:ring-blue-500 
                                                focus:border-blue-500 block 
                                                w-3/4
                                                p-2.5 
                                                dark:bg-gray-700 
                                                dark:border-gray-600 dark:placeholder-gray-400 
                                                dark:text-white dark:focus:ring-blue-500 
                                                dark:focus:border-blue-500"
                                                id="q3Value"
                                                value={q3Value}
                                                onChange={(e) => setQ3Value(e.target.value)}
                                                >

                                                </textarea>
                            </div>
                    </div>   

                    <div className="mb-10">                        
                        <button 
                            className="
                                bg-gray-50 
                                border 
                                border-gray-300
                                text-lg
                                rounded-lg 
                                p-2
                                pl-5
                                pr-5"
                                onClick={submitForm}
                                >
                                    Submit
                        </button>
                    </div>

                </div>
            </div>
        </div>
        <Spinner isVisible={showSpinner}/>  
    </>

  )
}

export default QuestionnairePage;