import React, {useState, useEffect} from 'react';
import Input from '../components/Input';
import { useNavigate, useSearchParams } from "react-router-dom";
import { DataStore } from '../stores/DataStore';
import axios from 'axios';

const IntroductionPage = () => {

  const navigate = useNavigate();
  
  const [searchParams, setSearchParams] = useSearchParams();

  const [contentToShow, setContentToShow] = useState("content1");

  const [prolificID, setProlificID] = useState("");
  const [studyID, setStudyID] = useState("");
  const [sessionID, setSessionID] = useState("");

  const verifyProlificID = () => {

    axios.post('/api/validate-prolific-id', {
      prolific_id : prolificID,
      study_id : studyID,
      session_id : sessionID,
    }).then((response) => {
      if(response.data == 'True') {
        setContentToShow("content2");
      } else {
        DataStore.update(data => {
          data.prolificID = prolificID,
          data.studyID = studyID,
          data.sessionID = sessionID,
          data.trialStarted = true
        });
        navigate('/PIS');
      }
    });
  }

  const verifyButton = () => {
    return <button 
    onClick={() => verifyProlificID()}
    className="
      bg-gray-50 
      border 
      border-gray-300
      mt-5
      p-2
      pl-5
      pr-5
    ">Continue</button>
  }

  const invalidMessage = () => {
    return <div className="mt-5 text-red-500 text-xl">Sorry you've already taken this study.</div>
  }

  const invalidURLMessage = () => {
    return <div className="mt-5 text-red-500 text-xl">Missing or invalid URL parameters!</div>
  }

  const renderConditionalContents = () => {

    switch(contentToShow) {
      case 'content1' :
        return verifyButton();
      case 'content2' :
        return invalidMessage();
      case 'content3' :
        return invalidURLMessage();
    }

  }


  useEffect(() => {
    setProlificID(searchParams.get("PROLIFIC_PID"));
    setStudyID(searchParams.get("STUDY_ID"));
    setSessionID(searchParams.get("SESSION_ID"));

    console.log(prolificID);

    if(!searchParams.get("PROLIFIC_PID")) {
      setContentToShow('content3');
    }

  },[]);
  return (
    <div className="flex justify-center">
        <div 
            className="
              container
              w-10/12
            ">
            <h1 
              className="
              text-3xl 
              font-semibold 
              text-slate-600
              mt-3
            ">Welcome</h1>
            <p className="
              mt-3
            ">If you are ready to participate in this study, please click the continue button below.  We will verify your prolific ID to make sure you haven't taken this study before.</p>
            { renderConditionalContents() }
        </div>      
    </div>
  )
}

export default IntroductionPage;
