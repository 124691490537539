import React, {useEffect, useState} from 'react';
import { DataStore } from '../stores/DataStore';
import { useNavigate } from "react-router-dom";

const DoneTrialItemPage = () => {

  const [redirectNow, setRedirectNow] = useState(true);

  const navigate = useNavigate();
  const { prolificID, currentTrial, totalTrial, isStartTimer, initialTime, timer, trialStarted } = DataStore.useState(data => data);

  useEffect(() => {
    if(redirectNow & trialStarted) {
      setTimeout(() => {

        DataStore.update(data => {
          data.timer = 10;
          data.isStartTimer = false;
          data.currentTrial = currentTrial + 1;
        });

        setRedirectNow(false);
        navigate("/trial-item");
      }, 3000);
    } else {
      navigate("/");
    }
  },[redirectNow]);
  return (
    <div className="flex justify-center">
        <div 
        className="
          container
          w-10/12
        ">
            <h1 className="
                text-3xl 
                font-semibold 
                text-slate-600
                mt-3
                mb-3
            ">
                Done Trial {currentTrial} of {totalTrial}
            </h1>
            <h2 className="
                            text-xl 
                            font-semibold 
                            text-slate-800
                            mt-3
                            mb-3
                ">
              Please wait. We will redirect you the next trial.
            </h2>
        </div>
    </div>
  )
}

export default DoneTrialItemPage;