import React, {useEffect} from 'react';
import { DataStore } from '../stores/DataStore';


import { useNavigate } from "react-router-dom";

const TrialPage = () => {


  const navigate = useNavigate();
  const { prolificID, currentTrial, totalTrial, trialStarted, initialTime } = DataStore.useState(data => data);

  const startTrial = () => {

    DataStore.update(data => {
      data.currentTrial = 1
    });

    navigate("/trial-item")

  }

  useEffect(() => {
    if(!trialStarted) {
      navigate("/")
    }
  },[]);
  return (
    <div className="flex justify-center">
      <div 
      className="
        container
        w-10/12
      ">
        <h1 className="
              text-3xl 
              font-semibold 
              text-slate-600
              mt-3
              mb-3
        ">
          Experiment Instructions
        </h1>

        <h2 className="
                        text-1xl 
                        font-semibold 
                        text-slate-400
                        mt-3
                        mb-3
        ">
          Your prolific ID: {prolificID}
        </h2>
        <p className="
            mb-3
        ">
          This experiment consists of {totalTrial} trials. Each trial will last {initialTime} seconds.
        </p>
        <p className="
            mb-3
        ">To continue to the experiment, please click on the "proceed" button below.</p>

          <div>
            <button 
                  onClick={startTrial}
                  className="
                    bg-gray-50 
                    border 
                    border-gray-300
                    mt-5
                    p-2
                    pl-5
                    pr-5
                  ">Click to proceed</button>      
          </div>
      </div>


    </div>
  )
}

export default TrialPage;