import React, {useEffect} from 'react';
import { DataStore } from '../stores/DataStore';
import Canvas from '../components/Canvas';
import { useNavigate } from "react-router-dom";

const TrialItemPage = () => {

const { prolificID, currentTrial, totalTrial, trialStarted } = DataStore.useState(data => data);
const navigate = useNavigate();



useEffect(() => {
    if(!trialStarted) {
      navigate("/")
    }
  },[]);
return (
        <div className="flex justify-center">
            <div 
            className="
              container
              w-10/12
            ">
                <h1 className="
                    text-3xl 
                    font-semibold 
                    text-slate-600
                    mt-3
                    mb-3
                ">
                Trial {currentTrial} of {totalTrial}
                </h1>
    
                <h2 className="
                            text-1xl 
                            font-semibold 
                            text-slate-400
                            mt-3
                            mb-3
                ">
                Your prolific ID: {prolificID}
                </h2>

               
    
                <p className="
                    mb-3
                ">
                    To begin the trial, click on the start trial button. This will go 'fullscreen', hiding everything else on your display. After ten seconds you will continue to the next trial.
                    Please do not click the forward or back buttons on your browser. 
                </p>
                
                <p className="
                    mb-3
                ">
                   Moving your mouse around changes what's presented to you in the experiment. Move your mouse around and try and see what is going on. 
                </p>

                <p className="
                    mb-3
                ">
                   <strong> When performing the experiment, please only use a computer mouse. Do not use any other form of input such as a keyboard, trackpad, touchscreen, or game controller, etc. </strong>
                </p>


                <Canvas />           
            </div>
            
        </div>
    );
 }


export default TrialItemPage;