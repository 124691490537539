import React, {useEffect, useState} from 'react'
// import prolificJson from '../mockdata/prolific.json';
import { Spinner } from '../components/Spinner';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import * as FaIcons from "react-icons/fa";

const ProlificData = () => {
  
  const [prolificJson, setProlificJson] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);

  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;

  const pageCount = Math.ceil(prolificJson.length / itemsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };


  const displayItems = prolificJson
        .slice(pagesVisited, pagesVisited + itemsPerPage)
        .map((d, index) => {
            return (
                <tr key={index}>
                    <td>{d.id}</td>
                    <td>{d.prolific_id}</td>
                    <td>{d.study_id}</td>
                    <td>{d.session_id}</td>
                    <td>{d.is_complete == 1 ? 'True' : 'False'}</td>
                </tr>             
            )
        });
 
  useEffect(() => {
    setShowSpinner(true);
    axios.get(
        "/api/prolific-list"
      ).then((response) => {
          console.log(response.data);
          setProlificJson(response.data);
          setShowSpinner(false);
      });

  }, []);
  return (
    <>
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Prolific ID</th>
                    <th>Study ID</th>
                    <th>Session ID</th>
                    <th>Is Complete</th>
                </tr>
            </thead>
            <tbody>
                {displayItems}
            </tbody>
        </table>  
        {pageCount > 0 && (
                <nav className="pagination" role="navigation" aria-label="pagination">
                    <ReactPaginate
                        previousLabel={
                        <FaIcons.FaChevronLeft className="pagination-previous" />
                        }
                        nextLabel={<FaIcons.FaChevronRight className="pagination-next" />}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        pageClassName={"pagination-link"}
                        containerClassName={"pagination-list"}
                        previousLinkClassName={"previous-btns"}
                        nextLinkClassName={"next-btns"}
                        disabledClassName={"pagination-disabled"}
                        activeClassName={"is-current"}
                    />
                </nav>
          )}         
        <Spinner isVisible={showSpinner}/>     
    </>
  )
}

export default ProlificData;