import React, {useState, useEffect} from 'react'
import { Spinner } from '../components/Spinner';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { DataStore } from '../stores/DataStore';
import { Navigation } from '../components/Navigation';
import ProlificData from '../components/ProlificData';
import QuestionnaireData from '../components/QuestionnaireData';
import InitializeData from '../components/InitializeData';

const AdminPage = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showData, setShowData] = useState(1);

  const { isAdminLoggedIn, showLoginError } = DataStore.useState(data => data); 

  const navigate = useNavigate();

  const navigationAction = (action) => {
    switch(action) {
      case "prolific": setShowData(1); break;
      case "questionnaire": setShowData(2); break;
      case "initialisedb": setShowData(3); break;
      case "logout": window.location.href = "/login"; break;
    }
  }

  const showContent = (param) => {
    switch(param) {
      case 1:
        return <><ProlificData /></>;
        break;
      case 2:
        return <><QuestionnaireData /></>;
        break;
      case 3:
        return <><InitializeData /></>;
        break;
    }
  }

  useEffect(() => {
    if(!isAdminLoggedIn) {
        navigate('/login')
    }
  }, [])
  return (    
    <div className="relative flex min-h-screen
                      flex-col justify-center overflow-hidden
                      py-6 sm:py-12
                      place-items-center
                      ">
          <div className="text-center mb-5 text-gray-700 text-2xl font-semibold">Admin Page</div>
          <div className="container">
              <Navigation onClick={(navAction) => navigationAction(navAction)} />            
          </div>
          <div 
          className="
            container
          ">
                <div className='
                ml-5 mr-5 relative
                bg-white px-6 pt-10 pb-8
                shadow-xl ring-1
                ring-gray-900/5
                md:mx-auto
                md:max-w-4xl
                md:rounded-lg
                md:px-10 border-2
                border-sky-500
                flex justify-center flex-col
                '>
                  {showContent(showData)}
                </div>
          </div>
                      
    </div>
  )
}

export default AdminPage;