import React, {useState, useEffect} from 'react';
import {ImUser} from 'react-icons/im';
import {ImFileText} from 'react-icons/im';
import {ImExit} from 'react-icons/im';
import {ImSpinner11} from 'react-icons/im';

export const Navigation = ({onClick}) => {

    return (
        <div className="border-0 md:mx-auto md:max-w-4xl flex items-start mb-5">
            <div className="text-gray-700 hover:text-sky-400 flex items-start mr-6 cursor-pointer" onClick={() => onClick("prolific")}><ImUser className="text-2xl mr-2" />Prolific Users</div>
            <div className="text-gray-700 hover:text-sky-400 flex items-start mr-6 cursor-pointer" onClick={() => onClick("questionnaire")}><ImFileText  className="text-2xl mr-2" />Questionnaire</div>
            <div className="text-gray-700 hover:text-sky-400 flex items-start mr-6 cursor-pointer" onClick={() => onClick("initialisedb")}><ImSpinner11  className="text-2xl mr-2" />Initialise DB</div>
            <div className="text-gray-700 hover:text-sky-400 flex items-start cursor-pointer" onClick={() => onClick("logout")}><ImExit  className="text-2xl mr-2" />Logout</div>
        </div>
    )
}