import React, {useState, useEffect} from 'react'; 
import Input from '../components/Input';
import { useNavigate, useSearchParams } from "react-router-dom";
import { DataStore } from '../stores/DataStore';
import axios from 'axios';

export const PISPage = () => {
  const navigate = useNavigate();
  const consent = () => {
        navigate("/trial")
      }
  return (
    <div>
        <div className="flex justify-center">
            <div className="container 
            w-10/12"
            >
                <h1 
                className="
                text-3xl 
                font-semibold 
                text-slate-600
                mt-3
                ">Participant Information Sheet</h1>
                <p className="
                mt-3
                mb-6
                ">Principal Investigators: Matthew Egbert (School of Computer Science); Chris Erb (School of Psychology)<br /> 
                Student completing honours research: Natasha Skuljan <br /> 
                Approval to conduct research was granted on the 31/05/2023<br /> </p>


                <h2 
                className="
                text-lg
                font-semibold 
                text-slate-600
                mt-3
                ">Researcher introduction</h2>
                <p className="
                mt-3
                mb-6
                ">This study is directed and managed by Dr Matthew Egbert, 
                a senior lecturer in the School of Computer Science at the University of Auckland.</p>

                <h2 
                className="
                text-lg
                font-semibold 
                text-slate-600
                mt-3
                ">Project description and invitation</h2>
                <p className="
                mt-3
                mb-6
                ">You are invited to participate in this research because you are an internet user on Prolific who clicked on a link to our study. 
                The purpose of this study is to better understand how people learn the relationship between how they move and what they perceive. 
                If you do choose to participate, geometric shapes and/or regions of color will be displayed on your computer’s display. The shapes and/or colors will change in response to your mouse movements. This display may include flickering (fast changing between dark and light colors). If you have photosensitive epilepsy you should not participate in this experiment. During the experiment, you are given several seconds to explore how your mouse movements affect what is displayed on your screen. You will then be asked to fill in a web-based questionnaire that poses a few short questions about your experience.
                Participants will need be aged between 18 and 65, understand English, have full mobility of their upper limbs and must be sighted. The experiment must be performed using a mouse (not a trackpad, touchscreen or other computer interface).
                The study should take about 10 minutes of your time and participants who complete the study will be compensated $3.50 NZ for their time via Prolific.
                The project is funded by the School of Computer Science, University of Auckland.</p>

                
                <h2 
                className="
                text-lg
                font-semibold 
                text-slate-600
                mt-3
                ">Anonymity and consent</h2>
                <p className="
                mt-3
                mb-6
                ">Participation in this study is voluntary and anonymous. By clicking the button labelled 
                “I consent to participating in this experiment and would like to proceed” at the bottom of this page, 
                you are consenting to participate in this study. Your identity will not be known to the research team. 
                We are only provided with your “Prolific ID” which we are unable to use to identify you. 
                After collection, the data that we record during the experiment will be stored on an access-restricted 
                network hosted by the University of Auckland, and only members of our research team will have access to this data. 
                Data recorded during your trials are indexed by your Prolific ID number, which we cannot use to identify you. 
                The data collected in this study will be stored indefinitely for research purposes. 
                Your data could be placed in online data repositories for others to access. 
                If the data is published in this way, we will not publish your Prolific ID with it and as such the data will at no time be identifiable as yours. 
                All information not pertaining to our research will be deleted (e.g., data regarding when the experiment was conducted). </p>

                <h2 
                className="
                text-lg
                font-semibold 
                text-slate-600
                mt-3
                ">Risks & withdrawal</h2>
                <p className="
                mt-3
                mb-6
                ">If you have photosensitive epilepsy, there is a risk that any flickering images displayed on your computer screen may induce a seizure. If you feel disorientated, uncomfortable or unwell during the experiment, your should stop your participation immediately. 
                You may withdraw your participation from this study at any time without giving a reason and without any adverse consequences to you. You are also free to skip any question(s) that you do not want to answer.</p>

                <h2 
                className="
                text-lg
                font-semibold 
                text-slate-600
                mt-3
                ">Data storage, retention, destruction, and future use</h2>
                <p className="
                mt-3
                mb-6
                ">This study will be administered online. 
                All data will be collected on a computer and stored in an electronic format. 
                As noted above, the data collected will be anonymized and then stored on the access-restricted network hosted by the 
                University of Auckland. During our initial analysis of the data, only members of our research team will have access to 
                this data. The data will be stored indefinitely for research purposes. 
                At a later date, the data recored may be placed in online data repositories for others to access, 
                but the data will at no time be identifiable as yours and all information not pertaining to our questionnaire 
                will have been removed from these data files (e.g., data regarding when the experiment was conducted). 
                Our findings will be reported at academic conferences, 
                written reports in journals or books, or written in student theses or dissertations.</p>

                <h2
                className="
                text-lg
                font-semibold 
                text-slate-600
                mt-3
                ">Contact details</h2>
                <p className="
                mt-3
                mb-6
                ">Should you have any questions or concerns, please contact: <br />
                • Dr. Matthew Egbert, Prinicipal Investigator and Senior Lecturer, School of Computer Science, University of Auckland (m.egbert@auckland.ac.nz) <br />
                • Professor Giovanni Russello, Head of the School of Computer Science (g.russello@auckland.ac.nz)</p>

                <h2
                className="
                text-lg
                font-semibold 
                text-slate-600
                mt-3
                ">UAHPEC chair contact details</h2>
                <p className="
                  mt-3
                  mb-6
                ">For any queries regarding ethical concerns you may contact the Chair, University of Auckland Human Participants Ethics Committee, Ethics and Integrity Team, University of Auckland, 
                Private Bag 92019, Auckland 1142. Telephone: +64 09 373-7599 ext. 83711. Email: humanethics@auckland.ac.nz All communications with the UAHPEC regarding this application should indicate this reference number: UAHPEC25513.</p>

                  <div style={{ marginBottom: '20px' }}>
                    <button 
                          onClick={consent}
                          className="
                            bg-gray-50 
                            border 
                            rounded-lg
                            border-gray-300
                            mt-5
                            p-2
                            pl-5
                            pr-5
                          ">I consent to participating in this experiment and would like to proceed</button>   
                  </div>   
            </div>
        </div>      
    </div>
  )
}

